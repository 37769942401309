<template>
  <div>
    <a-row :gutter="[16, 16]">
      <a-col :span="24" style="max-width: 256px">
        <a-range-picker :value="[searchForm.start_date, searchForm.end_date]" @change="onChangePicker" />
      </a-col>
      <a-col :span="24" style="max-width: 256px">
        <a-select v-model="searchForm.warehouse" :placeholder="$t('仓库')" allowClear style="width: 100%" @change="search">
          <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :span="24" style="max-width: 256px">
        <ClientSelect v-model="searchForm.client" :placeholder="$t('货主')" @change="search" />
      </a-col>
      <a-col :span="24" style="max-width: 256px">
        <a-input v-model="searchForm.search" :placeholder="$t('上架单号, 入库单号')" allowClear @pressEnter="search" />
      </a-col>
      <a-col :span="24" style="max-width: 100px">
        <a-button type="primary" icon="search" @click="search">{{ $t("查询") }}</a-button>
      </a-col>
    </a-row>

    <div>
      <a-table
        rowKey="id"
        size="small"
        :columns="columns"
        :data-source="items"
        :pagination="pagination"
        :loading="loading"
        @change="onChangeTable"
        :scroll="{ x: 1500 }"
      >
        <template slot="number" slot-scope="value, item">
          <a @click="toShelveOrderDetail(item)">{{ value }}</a>
        </template>
        <template slot="receipt_order_number" slot-scope="value, item">
          <a @click="toReceiptOrderDetail(item)">{{ item.receipt_order_item.number }}</a>
        </template>
        <div slot="action" slot-scope="value, item, index">
          <a-button-group size="small">
            <a-button type="default" @click="toShelveOrderDetail(item)">{{ $t("详情") }}</a-button>
            <a-button type="primary" @click="toShelveRecordForm(item)">{{ $t("上架") }}</a-button>
            <a-button type="primary" @click="toPalletShelveForm(item)">{{ $t("容器上架") }}</a-button>
          </a-button-group>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
import { shelveOrderList } from "@/api/stockIn";
import { warehouseOption } from "@/api/option";
import moment from "moment";

export default {
  components: {
    ClientSelect: () => import("@/components/ClientSelect/"),
  },
  data() {
    return {
      columns: [
        {
          title: this.$t("序号"),
          dataIndex: "index",
          fixed: "left",
          width: 60,
          customRender: (value, item, index) => index + 1,
        },
        {
          title: this.$t("上架单号"),
          dataIndex: "number",
          fixed: "left",
          width: 180,
          ellipsis: true,
          scopedSlots: { customRender: "number" },
        },
        {
          title: this.$t("入库通知单号"),
          dataIndex: "receipt_order_number",
          width: 180,
          ellipsis: true,
          scopedSlots: { customRender: "receipt_order_number" },
        },
        {
          title: this.$t("仓库"),
          dataIndex: "warehouse_item",
          width: 120,
          ellipsis: true,
          customRender: (item) => item.name,
        },
        {
          title: this.$t("货主"),
          dataIndex: "client_item",
          width: 120,
          ellipsis: true,
          customRender: (item) => item.name,
        },
        {
          title: this.$t("应上架数量"),
          dataIndex: "total_quantity",
          width: 120,
          ellipsis: true,
        },
        {
          title: this.$t("待上架数量"),
          dataIndex: "remain_quantity",
          width: 120,
          ellipsis: true,
        },
        {
          title: this.$t("状态"),
          dataIndex: "status",
          width: 100,
          ellipsis: true,
          customRender: (value, item, index) => {
            return item.is_void ? this.$t("已作废") : item.is_completed ? this.$t("已完成") : this.$t("进行中");
          },
        },
        {
          title: this.$t("创建人"),
          dataIndex: "creator_item",
          width: 120,
          ellipsis: true,
          customRender: (item) => item.name,
        },
        {
          title: this.$t("创建时间"),
          dataIndex: "create_time",
          sorter: true,
          width: 180,
          ellipsis: true,
        },
        {
          title: this.$t("操作"),
          dataIndex: "action",
          fixed: "right",
          width: 260,
          scopedSlots: { customRender: "action" },
        },
      ],
      pagination: { current: 1, total: 0, pageSize: 16, showTotal: (total) => `${this.$t("共")} ${total} ${this.$t("条")}` },
      searchForm: { page: 1, is_completed: false, is_void: false },
      loading: false,
      items: [],

      warehouseItems: [],
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
  },
  methods: {
    initData() {
      this.searchForm.warehouse = this.currentWarehouse;
      this.searchForm = { ...this.searchForm, ...this.$functions.parseParams(this.$route.query) };
      this.list();

      warehouseOption({ page_size: 999999 }).then((data) => {
        this.warehouseItems = data.results;
      });
    },
    onChangeTable(pagination, filters, sorter) {
      this.pagination = pagination;
      this.searchForm.page = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    onChangePicker(date, dateString) {
      let startDate = date[0],
        endDate = date[1];
      this.searchForm.start_date = startDate ? startDate.format("YYYY-MM-DD") : undefined;
      this.searchForm.end_date = endDate ? endDate.format("YYYY-MM-DD") : undefined;
      this.search();
    },
    search() {
      this.pagination.current = 1;
      this.searchForm.page = 1;
      this.list();
    },
    list() {
      let searchForm = { ...this.searchForm };
      if (searchForm.end_date) {
        searchForm.end_date = moment(searchForm.end_date).add(1, "days").format("YYYY-MM-DD");
      }

      this.loading = true;
      this.$router.push({ query: this.searchForm });
      shelveOrderList(searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    toReceiptOrderDetail(item) {
      this.$router.push({ path: "/stock_in/receipt_order_detail", query: { id: item.receipt_order_item.id } });
    },
    toShelveOrderDetail(item) {
      this.$router.push({ path: "/stock_in/shelve_order_detail", query: { id: item.id } });
    },
    toShelveRecordForm(item) {
      this.$router.push({
        path: "/stock_in/shelve_record_create",
        query: { id: item.id, warehouse: item.warehouse, area: item.receipt_order_item.area },
      });
    },
    toPalletShelveForm(item) {
      this.$router.push({
        path: "/stock_in/pallet_shelve",
        query: { id: item.id, warehouse: item.warehouse, area: item.receipt_order_item.area },
      });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
